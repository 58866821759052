import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import './index.css'
import { CardActions } from "@mui/material";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © ThoughtSTEM, LLC "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const cards = [
  {
    image: "homepage-images/coding-camp.jpg",
    heading: "After-school Programs & Camps",
    description:
      "From 2012 until the COVID-19 pandemic, ThoughtSTEM operated computer science after-school programs and camps in the Greater San Diego Area.",
    link: "/old-homepage.html",
    link_text: "Visit Our Old Website"
  },
  {
    image: "homepage-images/learntomod.png",
    heading: "LearnToMod",
    description:
      "A tool for computer science education: students learn how to code by modifying (modding) their favorite video game, Minecraft. Supported by the National Science Foundation.",
    link: "https://www.learntomod.com",
    link_text: "Buy LearnToMod"
  },
  {
    image: "homepage-images/dont-teach-coding-book-cover.png",
    heading: "Don't Teach Coding",
    description:
      "Authored by ThoughtSTEM's co-founders, this book is written for teachers and parents aspiring to be computer science educators.",
    link: "https://www.dont-teach.com/",
    link_text: "Buy Our Book"
  },
  {
    image: "homepage-images/codespells.png",
    heading: "CodeSpells",
    description:
      "An immersive fantasy video game where you can code your own magic spells.",
    link: "https://codespells.org",
    link_text: "Learn More"
  },
  {
    image: "homepage-images/software-consulting.png",
    heading: "Software Consulting",
    description:
      "People like to hire us to create games, websites, and other business solutions.",
    link: "mailto:contact@thoughtstem.com",
    link_text: "Hire Us"
  },
  {
    image: "homepage-images/child-coding-in-weekly-class-camp.png",
    heading: "MetaCoders",
    description:
      "A non-profit organization for promoting grass-roots coding education across the world (on pause as of 2020).",
    link: "https://www.metacoders.org",
    link_text: "Visit Our Old Website"
  },
];

const theme = createTheme({
  typography: {
    "fontFamily": "Quicksand"
  }
});

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="relative" elevation={0} sx={{pt:2, pb:2, backgroundColor: "#94efa8"}}>
        <Toolbar>
          <img
            src="thoughtstem_web_logo_black.png"
            alt="ThoughtSTEM logo"
            height="75px"
          />
        </Toolbar>
      </AppBar>
      <main>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: "background.paper",
            pt: 8,
          }}
        >
          <Container maxWidth="lg">
            <Typography
              component="h1"
              variant="h3"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Innovating in Education Since 2012
            </Typography>
          </Container>
        </Box>
        <Container sx={{ py: 8 }} maxWidth="xl">
          {/* End hero unit */}
          <Grid container spacing={4}>
            {cards.map((card) => (
              <Grid item key={card} xs={12} sm={6} md={4}>
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardMedia
                    component="img"
                    sx={{
                      height: 250
                    }}
                    image={card.image || "https://source.unsplash.com/random"}
                    alt="random"
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {card.heading}
                    </Typography>
                    <Typography>
                      {card.description}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <a target="_blank" rel="noreferrer" style={{margin: "auto"}} href={card.link}><Button sx={{width: "200px"}} variant="outlined">{card.link_text}</Button></a>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
      {/* Footer */}
      <Box sx={{ bgcolor: "background.paper", p: 6 }} component="footer">
        <Typography variant="h6" align="center" gutterBottom>
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
        >
        </Typography>
        <Copyright />
      </Box>
      {/* End footer */}
    </ThemeProvider>
  );
}
